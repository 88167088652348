import styled from 'styled-components';

export const UnorderedList = styled.ul`
  list-style-type: square;
  padding: 0 0 0 2rem;
`;

export const OrderedList = styled.ol`
  padding: 0 0 0 2rem;

  &::before {
    font-size: 1.25rem;
  }
`;

export const ListItem = styled.li``;
