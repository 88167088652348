import styled from 'styled-components';
import { hrGradient } from '../../../../../styles/color';

const HorizontalRule = styled.hr`
  background-image: linear-gradient(to right, ${hrGradient});
  border: 0;
  height: 1px;
`;

export default HorizontalRule;
