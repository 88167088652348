import styled from 'styled-components';
import { captionColor, boxShadow, textShadow, whiteAlpha3, fg } from '../../../../../styles/color';

const Quote = styled.blockquote`
  background-color: ${whiteAlpha3};
  border-left: 0.5rem solid ${captionColor};
  box-shadow: ${boxShadow};
  color: ${fg};
  font-size: 1.15rem;
  font-style: italic;
  line-height: 1.5;
  margin: 0 1rem 0 1.5rem;
  padding: 0.75rem 1.25rem 0.95rem 1rem;
  text-shadow: ${textShadow};
`;

export default Quote;
