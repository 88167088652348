import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fluidRange } from 'polished';
import { whiteAlpha2 } from '../../../../../styles/color';
import { sizes } from '../../../../../styles/mq';
import Code from '../Marks/CodeMark';

const PreCode = styled.pre`
  background-color: ${whiteAlpha2};
  border-radius: 0.25rem;
  clear: both;
  hyphens: none;
  max-width: 100%;
  min-width: 100px;
  overflow: hidden;
  padding: 0.75rem 1.25rem;
  position: relative;
  tab-size: 2;
  word-break: normal;

  code {
    background-color: initial;
    display: block;
    font-size: 0.875rem;
    line-height: 1.75;
    padding: 0;

    ${fluidRange(
      {
        prop: `font-size`,
        fromSize: `14px`,
        toSize: `20px`,
      },
      sizes.mobileL,
      sizes.desktop,
    )}
  }
`;

const CodeBlock = ({ code, language }) => (
  <PreCode className={`language-${language}`}>
    <Code code={code} language={language} className={`language-${language}`} />
  </PreCode>
);

export default CodeBlock;

CodeBlock.propTypes = {
  code: PropTypes.string.isRequired,
  language: PropTypes.oneOf([`javascript`, `css`, `html`]),
};

CodeBlock.defaultProps = {
  language: `javascript`,
};
