import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { dividerColor, captionColor } from '../../../../../styles/color';
import { sansSerif } from '../../../../../styles/fonts';
import EmbeddedImage from './EmbedTypes/EmbeddedImage';

const Figure = styled.figure`
  margin: 2rem 0;
`;

const FigCaption = styled.figcaption`
  border-bottom: 1px solid ${dividerColor};
  color: ${captionColor};
  font-family: ${sansSerif};
  font-size: 0.875rem;
  font-weight: 300;
  padding: 0.75em 1rem;
`;

const EmbeddedAsset = ({ node }) => {
  const {
    title: { 'en-US': title },
    description: { 'en-US': description },
  } = node.data.target.fields;

  return (
    <Figure>
      <EmbeddedImage node={node} />
      {(title || description) && <FigCaption>{title || description}</FigCaption>}
    </Figure>
  );
};

export default EmbeddedAsset;

EmbeddedAsset.propTypes = {
  node: PropTypes.shape({
    data: PropTypes.shape({
      target: PropTypes.shape({
        sys: PropTypes.object,
        fields: PropTypes.object,
      }),
    }),
  }).isRequired,
};
