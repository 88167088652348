import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { fg, whiteAlpha3, whiteAlpha2 } from '../../../../../styles/color';
import entryIcon from '../../../../../images/log/icon_log_entry.svg';
import { serif } from '../../../../../styles/fonts';
import { minWidth, sizes } from '../../../../../styles/mq';

const EntryLink = styled(Link)`
  align-items: center;
  background-color: ${whiteAlpha2};
  border-radius: 0.5em;
  color: ${fg};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin: 2rem;
  text-decoration: none;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: ${whiteAlpha3};
  }

  @media (${minWidth.mobileL}) {
    margin: 2rem auto;
    max-width: ${sizes.mobileL};
  }
`;

const IconArea = styled.div`
  padding: 0 1rem;
`;

const Icon = styled.img`
  display: block;
  height: 32px;
  width: 32px;
`;

const Content = styled.div`
  color: ${fg};
  padding: 0 0.25rem;
`;

const Name = styled.h3`
  font-size: 1.125rem;
  margin: 1rem 0;
`;

const Description = styled.p`
  flex: 1 1 75%;
  font-family: ${serif};
  font-size: 0.875rem;
`;

const EmbeddedEntry = ({ node }) => {
  const type = node.data.target.sys.contentType.sys.id;
  const { fields } = node.data.target;

  const isPost = type === `post`;
  return (
    <EntryLink to={isPost ? `/log/${fields.slug[`en-US`]}` : `/category/${fields.name[`en-US`]}`}>
      <IconArea>
        <Icon src={entryIcon} alt="Log Entry" width="64" height="64" />
      </IconArea>
      <Content>
        <Name>{isPost ? fields.title[`en-US`] : fields.name[`en-US`]}</Name>
        <Description>{isPost ? fields.summary[`en-US`] : fields.description[`en-US`]}</Description>
      </Content>
    </EntryLink>
  );
};

export default EmbeddedEntry;

EmbeddedEntry.propTypes = {
  node: PropTypes.shape({
    data: PropTypes.shape({
      target: PropTypes.shape({
        sys: PropTypes.object,
        fields: PropTypes.object,
      }),
    }),
  }).isRequired,
};
