import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CodeBlock from './CodeBlock';

const ParagraphTag = styled.p`
  font-size: 1.125rem;
  word-wrap: break-word;
`;

const Paragraph = ({ node, children }) => {
  const { content } = node;
  try {
    const [
      {
        marks: [mark],
        value,
      },
    ] = content;

    if (mark.type === `code`) {
      return <CodeBlock code={value} />;
    }
  } catch (e) {
    // handle the error if necessary
  }

  return <ParagraphTag>{children}</ParagraphTag>;
};

export default Paragraph;

Paragraph.propTypes = {
  node: PropTypes.shape({
    data: PropTypes.object,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
      }),
    ),
  }).isRequired,
};
