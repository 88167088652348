import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { fluidRange } from 'polished';
import { format, utcToZonedTime } from 'date-fns-tz';
import SEO from '../SEO';
import { sansSerif } from '../../styles/fonts';
import { sizes, minWidth } from '../../styles/mq';
import { captionColor, dividerColor } from '../../styles/color';
import Layout from '../layouts/Layout';
import RichText from './log/RichText';

const Main = styled.main`
  @media (${minWidth.mobile}) {
    margin: 0 auto;
    max-width: 100%;
  }
  @media (${minWidth.mobileXL}) {
    max-width: ${sizes.mobileXL};
  }
  @media (${minWidth.tablet}) {
    max-width: ${sizes.tablet};
  }
  @media (${minWidth.desktop}) {
    max-width: ${sizes.laptop};
  }
`;

const Article = styled.article`
  margin: 0 1rem;
`;

const Header = styled.header``;

const Title = styled.h1`
  font-family: ${sansSerif};
  font-size: 28px;
  margin-bottom: 0.5em;

  ${fluidRange(
    {
      prop: `font-size`,
      fromSize: `28px`,
      toSize: `48px`,
    },
    sizes.mobileXL,
    sizes.desktop,
  )}
`;

const Summary = styled.h2`
  font-family: ${sansSerif};
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 1em;

  ${fluidRange(
    {
      prop: `font-size`,
      fromSize: `18px`,
      toSize: `30px`,
    },
    sizes.mobileXL,
    sizes.desktop,
  )}
`;

const Date = styled.div`
  font-family: ${sansSerif};
`;

const Figure = styled.figure`
  margin: 2rem 0;
`;

const FigCaption = styled.figcaption`
  border-bottom: 1px solid ${dividerColor};
  color: ${captionColor};
  font-family: ${sansSerif};
  font-size: 0.875rem;
  font-weight: 300;
  padding: 0.75em 1rem;
`;

export default ({ data }) => {
  const {
    contentfulPost: {
      id,
      title: { title },
      summary: { summary },
      body: { json },
      dateTime,
      featuredImage,
    },
  } = data;

  const dateFormatted = format(
    utcToZonedTime(dateTime, `America/Los_Angeles`),
    `MMM d, yyyy — h:mma zzz`,
    {
      timeZone: `America/Los_Angeles`,
    },
  );

  return (
    <>
      <SEO title={title} />
      <Layout>
        <Main>
          <Article data-post-id={id}>
            <Header>
              <Title>{title}</Title>
              {summary && <Summary>{summary}</Summary>}
              <Date>
                <time dateTime={dateTime}>{dateFormatted}</time>
              </Date>
            </Header>
            {featuredImage && (
              <Figure>
                <Img fluid={featuredImage.fluid} alt={featuredImage.alt} />
                {featuredImage.caption && <FigCaption>{featuredImage.caption}</FigCaption>}
              </Figure>
            )}
            <RichText json={json} />
          </Article>
        </Main>
      </Layout>
    </>
  );
};

export const postQuery = graphql`
  query PostQuery($id: String!) {
    contentfulPost(id: { eq: $id }) {
      id
      slug
      dateTime
      title {
        title
      }
      category {
        id
        name
      }
      summary {
        summary
      }
      body {
        json
      }
      featuredImage {
        id
        title
        description
        fluid(maxWidth: 400, quality: 60) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;
