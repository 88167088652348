import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Prism from 'prismjs';
import { monospace } from '../../../../../styles/fonts';
import { whiteAlpha2 } from '../../../../../styles/color';

const Code = styled.code`
  background-color: ${whiteAlpha2};
  color: #fff;
  display: inline-block;
  font-family: ${monospace};
  padding: 0 0.25rem;
  white-space: pre;
`;

const CodeMark = ({ code, language }) => (
  <Code
    className={`language-${language}`}
    dangerouslySetInnerHTML={{
      __html: Prism.highlight(code, Prism.languages[`${language}`], language),
    }}
  />
);

export default CodeMark;

CodeMark.propTypes = {
  code: PropTypes.string.isRequired,
  language: PropTypes.oneOf([`javascript`, `css`, `html`]),
};

CodeMark.defaultProps = {
  language: `javascript`,
};
