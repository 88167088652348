import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { fg, whiteAlpha3, whiteAlpha2 } from '../../../../../styles/color';

const LinkStyle = css`
  color: ${fg};
  display: inline-block;
  /* display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center; */
  text-decoration: none;
`;

const GatsbyAnchor = styled(Link)`
  ${LinkStyle}
`;

const Anchor = styled.a`
  ${LinkStyle}
`;

const IconArea = styled.span`
  background-color: ${props => props.bgColor};
  border-radius: 0.25em 0 0 0.25em;
  overflow: hidden;
  padding: 0 0.2rem;
`;

const Icon = styled.img`
  display: inline-block;
  vertical-align: -6%;
`;

const Label = styled.span`
  background-color: ${whiteAlpha2};
  border-radius: ${props => (props.hasIcon ? `0 0.25em 0.25em 0` : `0.25em`)};
  color: ${fg};
  padding: 0 0.25rem;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: ${whiteAlpha3};
  }
`;

const InlineLink = ({ href, label, icon, iconBg }) => {
  const LinkComponent = ({ children }) => {
    if (href.match(/^\/log\/.+$/i)) {
      return <GatsbyAnchor to={href}>{children}</GatsbyAnchor>;
    }

    return (
      <Anchor href={href} target="_blank" rel="noopener noreferrer nofollow">
        {children}
      </Anchor>
    );
  };

  return (
    <LinkComponent>
      {icon && (
        <IconArea bgColor={iconBg}>
          <Icon src={icon} alt={label} width="16" height="16" />
        </IconArea>
      )}
      <Label hasIcon={icon}>{label}</Label>
    </LinkComponent>
  );
};

export default InlineLink;

InlineLink.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconBg: PropTypes.string,
};

InlineLink.defaultProps = {
  icon: null,
  iconBg: `#fff`,
};
