/* eslint-disable no-console, react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { MARKS, BLOCKS, INLINES } from '@contentful/rich-text-types';
import Quote from './Blocks/Quote';
import InlineHyperlink from './Inline/InlineHyperlink';
import InlineEntry from './Inline/InlineEntry';
import { Header1, Header2, Header3, Header4, Header5, Header6 } from './Blocks/Headings';
import { UnorderedList, OrderedList, ListItem } from './Blocks/Lists';
import HorizontalRule from './Blocks/HorizontalRule';
import Paragraph from './Blocks/Paragraph';
import Underline from './Marks/Underline';
import CodeMark from './Marks/CodeMark';
import EmbeddedEntry from './Blocks/EmbeddedEntry';
import EmbeddedAsset from './Blocks/EmbeddedAsset';

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <strong>{text}</strong>,
    [MARKS.ITALIC]: text => <em>{text}</em>,
    [MARKS.UNDERLINE]: text => <Underline>{text}</Underline>,
    [MARKS.CODE]: text => <CodeMark code={text} />,
  },
  renderNode: {
    // Inlines
    [INLINES.HYPERLINK]: node => <InlineHyperlink node={node} />,
    [INLINES.EMBEDDED_ENTRY]: node => <InlineEntry node={node} />,
    // Headings
    [BLOCKS.HEADING_1]: (node, children) => <Header1>{children}</Header1>,
    [BLOCKS.HEADING_2]: (node, children) => <Header2>{children}</Header2>,
    [BLOCKS.HEADING_3]: (node, children) => <Header3>{children}</Header3>,
    [BLOCKS.HEADING_4]: (node, children) => <Header4>{children}</Header4>,
    [BLOCKS.HEADING_5]: (node, children) => <Header5>{children}</Header5>,
    [BLOCKS.HEADING_6]: (node, children) => <Header6>{children}</Header6>,
    // Lists
    [BLOCKS.UL_LIST]: (node, children) => <UnorderedList>{children}</UnorderedList>,
    [BLOCKS.OL_LIST]: (node, children) => <OrderedList>{children}</OrderedList>,
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,

    [BLOCKS.QUOTE]: node => <Quote>{node.content[0].content[0].value}</Quote>,
    [BLOCKS.HR]: () => <HorizontalRule />,
    [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph node={node}>{children}</Paragraph>,
    // Embedded Blocks
    [BLOCKS.EMBEDDED_ENTRY]: node => <EmbeddedEntry node={node} />,
    [BLOCKS.EMBEDDED_ASSET]: node => <EmbeddedAsset node={node} />,
  },
};

const RichText = ({ json }) => documentToReactComponents(json, options);

export default RichText;

RichText.propTypes = {
  json: PropTypes.object.isRequired,
};
