import React from 'react';
import PropTypes from 'prop-types';
import mozIcon from '../../../../../images/log/icon_moz.svg';
import npmIcon from '../../../../../images/log/icon_npm.svg';
import ytIcon from '../../../../../images/log/icon_youtube.svg';
import gitIcon from '../../../../../images/log/icon_github.svg';
import InlineLink from './InlineLink';

const getIcon = hostname => {
  if (hostname.includes(`developer.mozilla.org`))
    return { icon: mozIcon, customBgColor: `#83d0f2` };
  if (hostname.includes(`npmjs`)) return { icon: npmIcon, customBgColor: `#c12127` };
  if (hostname.includes(`youtu`)) return { icon: ytIcon };
  if (hostname.includes(`github`)) return { icon: gitIcon };
  return null;
};

const InlineHyperlink = ({ node }) => {
  const {
    data: { uri },
    content: [{ value }],
  } = node;
  const url = new URL(uri);
  const { hostname, href } = url;
  const { icon, customBgColor } = getIcon(hostname);

  return <InlineLink href={href} label={value} icon={icon} iconBg={customBgColor} />;
};

export default InlineHyperlink;

InlineHyperlink.propTypes = {
  node: PropTypes.shape({
    data: PropTypes.object,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
      }),
    ),
  }).isRequired,
};
