import styled from 'styled-components';

export const Header1 = styled.h1`
  font-size: 1.875rem;
  margin: 0.66em 0;
`;

export const Header2 = styled.h2`
  font-size: 1.6rem;
  margin: 0.66em 0;
`;

export const Header3 = styled.h3`
  font-size: 1.4rem;
  margin: 0.66em 0;
`;

export const Header4 = styled.h4`
  font-size: 1.3rem;
  margin: 0.85em 0;
`;

export const Header5 = styled.h5`
  font-size: 1.15rem;
  margin: 1em 0;
`;

export const Header6 = styled.h6`
  font-size: 1rem;
  margin: 1.33em 0;
`;
