import React from 'react';
import PropTypes from 'prop-types';
import entryIcon from '../../../../../images/log/icon_log_entry.svg';
import InlineLink from './InlineLink';

const InlineEntry = ({ node }) => {
  const {
    data: {
      target: {
        fields: { title, slug },
      },
    },
  } = node;
  const label = title[`en-US`];
  const href = `/log/${slug[`en-US`]}`;

  return <InlineLink href={href} label={label} icon={entryIcon} iconBg="#4b5c66" />;
};

export default InlineEntry;

InlineEntry.propTypes = {
  node: PropTypes.shape({
    data: PropTypes.shape({
      target: PropTypes.shape({
        sys: PropTypes.object,
        fields: PropTypes.object,
      }),
    }),
  }).isRequired,
};
