import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const getSourceURL = (url, width, height, quality, webp = false) =>
  `${url}?w=${width}\\&h=${height}\\&q=${quality}${webp && `\\&fm=webp`} ${width}w`;

const getFluidSrc = (url, width, height, quality = 75) => {
  const sizes = [100, 200, 400, 600, 800, 1000];
  const srcSizes = sizes.map(size => [size, Math.round(height / (width / size))]);
  const srcSet = srcSizes.map(([w, h]) => getSourceURL(url, w, h, quality, false)).join(`,\n`);
  const srcSetWebp = srcSizes.map(([w, h]) => getSourceURL(url, w, h, quality, true)).join(`,\n`);

  return {
    aspectRatio: width / height,
    sizes: `(max-width: ${sizes[3]}px) 100vw, ${sizes[3]}px`,
    src: srcSet[3],
    srcSet,
    srcWebp: srcSetWebp[3],
    srcSetWebp,
  };
};

const EmbeddedImage = ({ node }) => {
  const {
    description: { 'en-US': description },
    file: {
      'en-US': {
        url,
        details: {
          image: { width, height },
        },
      },
    },
  } = node.data.target.fields;
  const fluid = getFluidSrc(url, width, height, 80);

  return <Img fluid={fluid} alt={description} />;
};

export default EmbeddedImage;

EmbeddedImage.propTypes = {
  node: PropTypes.shape({
    data: PropTypes.shape({
      target: PropTypes.shape({
        sys: PropTypes.object,
        fields: PropTypes.object,
      }),
    }),
  }).isRequired,
};
